import { IMG_KAYA_BUTTON_SEND, IMG_KAYA_BUTTON_SEND_DISABLED } from '../../constants';
import { useChatContext } from '../../contexts';
import { useChatActions } from '../../hooks';
import { SpeechButton, TextBox } from '../common';

const ChatActions = () => {
    const { streaming } = useChatContext();
    const { isTimesheet, listening, isValid, browserSupport, triggerMic, handleSubmit } = useChatActions();

    const buttonDisabled = !isValid && !streaming;
    const sendButtonIcon = buttonDisabled ? IMG_KAYA_BUTTON_SEND_DISABLED : IMG_KAYA_BUTTON_SEND;

    return (
        <div
            className="sm:fixed bottom-0 left-0 w-full bg-white ps-1 pe-6 pt-6 flex items-center border-t border-t-N-200"
            style={{ paddingBottom: 'calc(24px + env(safe-area-inset-bottom))' }}
        >
            {' '}
            <div className="flex items-center">
                <SpeechButton
                    listening={listening}
                    browserSupport={browserSupport}
                    isTimesheet={isTimesheet}
                    triggerMic={triggerMic}
                />
            </div>
            <div className="flex items-center justify-between w-full relative">
                <TextBox
                    isError={!isValid}
                    onSend={handleSubmit}
                    placeholder={isTimesheet ? 'Provide your update here...' : 'Ask your question here...'}
                    resizable
                />
                <div className="flex gap-x-2 items-center absolute right-0 top-[20px] pr-3">
                    <button
                        title={streaming ? 'Stop' : 'Send'}
                        disabled={buttonDisabled}
                        onClick={() => handleSubmit()}
                        type="button"
                        className="inline-flex items-center justify-center h-10 w-10 transition duration-150 ease-in-out text-N-700 hover:cursor-pointer hover:scale-110 focus:outline-none"
                    >
                        {streaming ? (
                            <i className="ri-stop-circle-line text-[24px]"></i>
                        ) : (
                            <img src={sendButtonIcon} alt="send_message_icon" />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export { ChatActions };
