import { Accordion } from '@kaya/kaya-ui-design-system-pb';
import { Source } from '../../../__generated__/graphql';
import { FactCheckResponse, LLMResponseCard } from './common';
import { useMutation } from '@apollo/client';
import { ADD_FACT_CHECK_MESSAGE, COMPARE_LLM_RESPONSES } from '../../../graphql';
import { useEffect, useRef, useState } from 'react';
import { useChatContentContext } from '../../../contexts';
import classNames from 'classnames';

type IFactCheckResponseContainer = {
    previousMessageID: string;
    previousResponse: string;
};

export enum GoogleFactCheckLoadingState {
    PREPARING_QUERIES = 'Preparing queries...',
    SEARCHING_INTERNET = 'Searching the internet...',
    GENERATING_RESPONSE = 'Generating the response...',
    COMPLETED = 'Complete',
}

export enum LLMCompareLoadingState {
    WAITING_FOR_GOOGLE_RESPONSE = 'Waiting for response from Google...',
    RETRIEVING_FACT_CHECK_ATTRIBUTES = 'Retrieving fact check attributes...',
    COMPARING_RESPONSES = 'Comparing Responses...',
    GENERATING_ANALYSIS = 'Generating the Analysis...',
    COMPLETED = 'Complete',
}

export const FactCheckResponseContainer = ({ previousMessageID, previousResponse }: IFactCheckResponseContainer) => {
    const [compareResponse, setCompareResponse] = useState<string | null>();
    const [factCheckLoadingMessage, setFactCheckLoadingMessage] = useState<GoogleFactCheckLoadingState>(
        GoogleFactCheckLoadingState.PREPARING_QUERIES
    );
    const [llmResponseLoadingMessage, setLLmResponseLoadingMessage] = useState<LLMCompareLoadingState>(
        LLMCompareLoadingState.WAITING_FOR_GOOGLE_RESPONSE
    );
    const [factCheckSeconds, setFactCheckSeconds] = useState<number>(0); // Track the number of seconds for the google fact check
    const [llmResponseSecond, setLlmResponseSeconds] = useState<number>(0); // Track the number of seconds for the LLM response check
    const [isFactCheckResponseExpand, setIsFactCheckResponseExpand] = useState(false);
    const [isFactChecked, setIsFactChecked] = useState(false);

    const isInitialRender = useRef(true);

    const { googleFactCheckContent, setGoogleFactCheckContent } = useChatContentContext();

    const [addFactCheckMessage, { loading: factCheckMsgLoading }] = useMutation(ADD_FACT_CHECK_MESSAGE);
    const [compareLLMResponse, { loading: llmComparisonLoading }] = useMutation(COMPARE_LLM_RESPONSES);

    const updateFactCheckLoadingMessage = (factCheckSeconds: number) => {
        switch (factCheckSeconds) {
            case 1:
                setFactCheckLoadingMessage(GoogleFactCheckLoadingState.SEARCHING_INTERNET);
                break;
            case 2:
                setFactCheckLoadingMessage(GoogleFactCheckLoadingState.SEARCHING_INTERNET);
                break;
            case 3:
                setFactCheckLoadingMessage(GoogleFactCheckLoadingState.GENERATING_RESPONSE);
                break;
        }
    };

    const updateLLMCompareLoadingMessage = (factCheckSeconds: number) => {
        switch (factCheckSeconds) {
            case 1:
                setLLmResponseLoadingMessage(LLMCompareLoadingState.RETRIEVING_FACT_CHECK_ATTRIBUTES);
                break;
            case 2:
                setLLmResponseLoadingMessage(LLMCompareLoadingState.COMPARING_RESPONSES);
                break;
            case 3:
                setLLmResponseLoadingMessage(LLMCompareLoadingState.GENERATING_ANALYSIS);
                break;
        }
    };

    useEffect(() => {
        const handleAddFactCheckMessage = async () => {
            if (previousMessageID) {
                const data = await addFactCheckMessage({ variables: { input: { messageId: previousMessageID } } });
                setGoogleFactCheckContent({
                    ...googleFactCheckContent,
                    factCheckMessageId: String(data.data?.addFactCheckMessage?.id),
                    factCheckMessage: String(data.data?.addFactCheckMessage?.content),
                });
                setIsFactChecked(true);
                setFactCheckLoadingMessage(GoogleFactCheckLoadingState.COMPLETED);
            }
        };

        if (isFactChecked === false) {
            handleAddFactCheckMessage();
        }

        return () => {
            setIsFactChecked(false);
        };
    }, [previousMessageID]);

    useEffect(() => {
        const handleComparisonData = async () => {
            const comparisonData = await compareLLMResponse({
                variables: { input: { messageId: String(googleFactCheckContent?.factCheckMessageId) } },
            });
            setCompareResponse(comparisonData.data?.compareLLMResponses);
            setLLmResponseLoadingMessage(LLMCompareLoadingState.COMPLETED);
        };

        if (factCheckLoadingMessage === GoogleFactCheckLoadingState.COMPLETED) {
            handleComparisonData();
        }
    }, [factCheckLoadingMessage]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFactCheckSeconds(prevSeconds => {
                const newSeconds = prevSeconds + 1;
                updateFactCheckLoadingMessage(newSeconds);

                if (newSeconds >= 3) {
                    clearInterval(intervalId);
                }

                return newSeconds;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (factCheckLoadingMessage === GoogleFactCheckLoadingState.COMPLETED) {
            const intervalId = setInterval(() => {
                setLlmResponseSeconds(prevSeconds => {
                    const newSeconds = prevSeconds + 1;
                    updateLLMCompareLoadingMessage(newSeconds);

                    if (newSeconds >= 3) {
                        clearInterval(intervalId);
                    }

                    return newSeconds;
                });
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [factCheckLoadingMessage]);

    return (
        <div
            className={classNames('h-[800px]', {
                '!h-auto': isFactCheckResponseExpand,
            })}
        >
            <div
                className={classNames('flex flex-col gap-y-5 pb-8  border-t-N-300', {
                    'h-full': isFactCheckResponseExpand,
                })}
            >
                <div
                    className={classNames('flex flex-col mt-3 gap-y-4', {
                        'h-full': isFactCheckResponseExpand,
                    })}
                >
                    <div
                        className={classNames('flex gap-x-4 sm:flex-col sm:gap-y-4', {
                            'h-full': isFactCheckResponseExpand,
                        })}
                    >
                        {!isFactCheckResponseExpand && (
                            <FactCheckResponse
                                loadingStateMessage={GoogleFactCheckLoadingState.COMPLETED}
                                label="Initial Response"
                                content={previousResponse}
                                sources={[]}
                                maximized={false}
                                maximizable={false}
                            />
                        )}
                        <FactCheckResponse
                            loadingStateMessage={factCheckLoadingMessage}
                            label="Fact Check Response"
                            content={String(googleFactCheckContent?.factCheckMessage)}
                            sources={[]}
                            isFactCheckedResponse
                            maximized={isFactCheckResponseExpand}
                            maximizable
                            onMaximize={() => setIsFactCheckResponseExpand(!isFactCheckResponseExpand)}
                        />
                    </div>
                    {!isFactCheckResponseExpand && (
                        <LLMResponseCard
                            compareResponse={compareResponse}
                            factCheckLoadingMessage={factCheckLoadingMessage}
                            loadingStateMessage={llmResponseLoadingMessage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
